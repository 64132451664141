<template>
  <div class="sealRenewal">
    <header>
      <div class="header-box">
        <div>续期</div>
      </div>
    </header>
    <div :class="['sealRenewalMain',{'isMobile': stepActive != -1}]" v-loading="loading">
      <div class="renewalUkey" v-if="stepActive == -1">
        <div class="renewalMatter">
          <div class="item-title">在线续期注意事项</div>
          <p>（1）确认是否下载并安装【豸信CA数字证书助手驱动】。</p>
          <p>（2）将需要续期的Ukey插入电脑USB接口，Ukey密码验证后进行续期。（若遇到控件拦截，需要全部运行控件）</p>
          <p>（3）推荐使用【IE】、【Chrome】、【Edge】、【360】浏览器。 </p>
          <p><span class="el-icon-warning"></span> 续期请插入一个Ukey操作，以免造成数据冲突，造成续期失败。</p>
        </div>
        <template>
          <div class="item-title">续期信息</div>
          <div class="item-box" v-if="info.organization">
            <div>证书序列号：<span>{{info.signCertSn}}</span></div>
            <div>所属机构/O：<span>{{info.organization}}</span></div>
            <div>所属部门/OU：<span>{{info.organizationalUnit}}</span></div>
            <template>
              <div>姓名/CN：<span>{{info.commonName}}</span></div>
              <div>证件类型：<span>{{IdentType(info.orgIdentType)}}</span></div>
              <div>证件号码：<span>{{ $util.IdNoSecret(info.orgIdentNo)}}</span></div>
            </template>
            <div>续期年限：<span>{{info.postponeYears}}年</span></div>
          </div>
          <div class="item-box" v-if="!info.organization">
            <div>证书名称/CN：<span>{{info.commonName}}</span></div>
            <div>持有人姓名：<span>{{info.personName}}</span></div>
            <div>持有人证件类型：<span>{{IdentType(info.personIdentType)}}</span></div>
            <div>持有人身份证号：<span>{{info.personIdentNo}}</span></div>
            <div>续期年限：<span>{{info.postponeYears}}年</span></div>
          </div>
          <div class="sealImg" v-if="info.patternFilePath && info.patternFilePath.length > 0"><img v-for="key in info.patternFilePath" :key="key" :src="key"></div>
          <el-button class="button" @click="connectUkey" type="primary">立即续期</el-button>
        </template>

      </div>
      <div class="renewaling" v-else-if="stepActive == 0">
        <div class="icon">
          <img class="img" src="@/assets/icons/service/loading-icon.png" alt="">
        </div>
        <div class="tt">证书更新中</div>
        <div class="hint">提示：请不要关闭浏览器，不要拔出Ukey，在此界面等待更新完毕，以免造成证书更新失败</div>
      </div>
      <div class="changeing" v-if="stepActive == 1">
        <div class="icon">
          <img src="@/assets/icons/service/check-success-icon.png" alt="">
        </div>
        <div class="tt">续期成功</div>
      </div>
      <div class="changeing" v-if="stepActive ==2">
        <div class="icon">
          <img src="@/assets/icons/service/check-fail-icon.png" alt="">
        </div>
        <div class="tt">续期失败，请重新申请</div>
      </div>
    </div>
    <UkeyLogin v-model="ukeyLoginShow" @logined="ukeyLogined" :Ukey="Ukey" :certSn="certSn" />
  </div>
</template>

<script>
import { IdentType } from '@/utils/typeMatch'
import { Ukey } from './ukey'
import UkeyLogin from './ukeyLogin.vue'
export default {
  data() {
    return {
      loading: false,
      ukeyLoginShow: false,
      Ukey: null,
      paramurl: '',
      IdentType,
      stepActive: -1,
      transId: '',
      transCode: '',
      certSn: '',
      info: {
        commonName: '',
        orgIdentNo: '',
        orgIdentType: '',
        postponeYears: 0,
        organization: '',
        organizationalUnit: '',
        signCertSn: '',
        patternFilePath: []
      }
    }
  },
  components: {
    UkeyLogin
  },
  created() {
    this.Ukey = new Ukey(this)
    this.getCheckURL()
  },
  methods: {
    ukeyLogined() {
      this.getPostponeCert()
    },
    getCheckURL() {
      if (this.$route.query.param) {
        this.paramurl = this.$route.query.param
      } else {
        this.$message.error('未获取到证书更新参数')
      }
      this.$api
        .onlineCheckCertUpdatePage({
          param: this.paramurl
        })
        .then(res => {
          if (res.code === '0') {
            this.transId = res.data.transId
            this.transCode = res.data.makeCertCode
            this.$api
              .onlineCheckCertPostponeInfo({
                transId: this.transId,
                makeCertCode: this.transCode
              })
              .then(res => {
                if (res.code === '0') {
                  this.info = res.data
                } else {
                  this.$message.error(res.message)
                }
              })
          } else {
            this.$message.error(res.message)
          }
        })
    },
    connectUkey() {
      this.loading = true
      this.Ukey.connect()
        .then(certSn => {
          this.certSn = certSn.toLowerCase()
          if (
            certSn !== this.info.signCertSn.toLowerCase() &&
            certSn !== this.info.newCertSn.toLowerCase()
          ) {
            this.$alert(
              '当前插入Ukey非办理续期业务所使用的，请更换Ukey',
              '提示',
              {
                confirmButtonText: '我知道了'
              }
            )
            this.loading = false
          } else {
            this.ukeyLoginShow = true
          }
        })
        .catch(err => {
          this.loading = false
          if (err.message) this.$message.error(err.message)
        })
    },
    getCheckCertFinish(item) {
      this.$api
        .onlineCheckCertFinish({
          transId: this.transId,
          certBase64: item
        })
        .then(() => {
          this.stepActive = 1
        })
        .catch(() => {
          this.stepActive = 2
        })
    },
    getPostponeCert() {
      this.stepActive = 0
      this.loading = false
      this.$api
        .onlineCheckCertPostpone({
          transId: this.transId,
          transCode: this.transCode,
          certSn: this.info.signCertSn
        })
        .then(res => {
          this.certData = res.data
          this.Ukey.writeCert(this.certData)
            .then(res => {
              if (res && this.info.isSealForm) {
                return this.Ukey.readUkeySeal(this.certData.sealDTO.length)
              } else if (res && !this.info.isSealForm) {
                return this.Ukey.checkUkey()
              } else {
                this.stepActive = 2
              }
            })
            .then(res => {
              console.log(res)

              if (res.code == -1) {
                this.loading = false
                this.stepActive = 2
              } else {
                let item = btoa(JSON.stringify(res))
                this.getCheckCertFinish(item)
                // this.stepActive = 1
              }
            })
            .catch(err => {
              this.$msgbox({
                title: '提示',
                message: `<div><p class="el-icon-error" style="color: #FC7163;font-size: 14px;"> 数字证书写入Ukey失败，请拔掉Ukey，再重新插入Ukey进行写入证书。</p></div>`,
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定重新写入',
                showClose: false
              }).then(() => {
                this.loading = true
                this.Ukey.hasUkey()
                  .then(() => {
                    this.ukeyLoginShow = true
                  })
                  .catch(err => {
                    this.loading = false
                    console.log(err)
                  })
              })
              this.stepActive = 2
              console.log(err)
            })
        })
        .catch(() => {
          this.stepActive = 2
        })
    }
  }
}
</script>
<style lang="scss">
.elCheckSuccess span {
  color: #fff !important;
}
.elCheckSuccess:hover span {
  color: #fff !important;
}
.elCheckSuccess span:hover {
  color: #fff !important;
}
</style>
<style lang="scss" scoped>
@keyframes loading {
  // 0% {
  //   transform: rotate(0deg);
  // }
  100% {
    transform: rotate(360deg);
  }
}
.sealRenewal {
  background: #f5f8fb;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  // bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  .button.el-button {
    margin-top: 30px;
  }
}
header {
  text-align: center;
  border-bottom: 1px #eaeaea solid;
  background-color: #fff;
  display: block;
  width: 100%;
  margin-bottom: 16px;
  .header-box {
    text-align: center;
    font-size: 16px;
    color: #333333;
    height: 60px;
    line-height: 60px;
    width: 100%;
    font-weight: bold;
  }
}
.sealRenewalMain {
  flex: 1;
  background: #fff;
  width: 1300px;
  margin: auto;
  // &.isMobile {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
}
.changeing {
  padding-top: 226px;
  text-align: center;
  height: 100px;

  .icon {
    width: 52px;
    height: 52px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px #e4e5e6;
    margin: 0 auto;
    border-radius: 50%;
    padding: 5px;
    img {
      width: 42px;
      height: 42px;
    }
    .img {
      animation: loading 0.6s linear infinite;
    }
  }
  .tt {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    margin: 24px auto;
  }
  .hint {
    width: 828px;
    background: #f7faff;
    border-radius: 2px;
    line-height: 94px;
    font-size: 16px;
    color: #3a6afe;
    margin: 0 auto;
  }
}
.renewaling {
  height: 718px;
  padding-top: 140px;
  text-align: center;
  .icon {
    width: 52px;
    height: 52px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px #e4e5e6;
    margin: 0 auto;
    border-radius: 50%;
    padding: 5px;
    img {
      width: 42px;
      height: 42px;
      animation: loading 0.6s linear infinite;
    }
  }
  .tt {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    margin: 24px auto;
  }
  .hint {
    width: 828px;
    background: #f7faff;
    border-radius: 2px;
    line-height: 94px;
    font-size: 16px;
    color: #3a6afe;
    margin: 0 auto;
  }
}
.item-title {
  // border-left: 3px $primaryColor solid;
  font-size: 14px;
  font-weight: bold;
  color: #303b50;
  line-height: 19px;
  position: relative;
  border-bottom: 1px #dee2e8 solid;
  padding: 8px 12px 16px;
  margin-bottom: 24px;
  .hint {
    color: #ff443e;
  }
  &::before {
    position: absolute;
    width: 3px;
    height: 16px;
    content: '';
    background: $primaryColor;
    top: 8px;
    left: 0;
  }
}
.commonPattern {
  img {
    width: 98px;
    margin: 0 8px;
    height: auto;
    display: inline-block;
  }
}
.renewalUkey {
  padding: 16px 24px 32px;
  .item-box {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 50%;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #6c737b;
      line-height: 19px;
      margin-bottom: 32px;
      display: flex;
      white-space: nowrap;
      span {
        font-size: 14px;
        font-weight: bold;
        color: #303b50;
        white-space: normal;
      }
    }
  }
  .el-button {
    width: 182px;
    display: block;
    margin: 0 auto;
  }
}
.renewalMatter {
  p {
    font-size: 14px;
    color: #303b50;
    margin-bottom: 32px;
  }
  p:last-child {
    color: #ffb500;
  }
}
</style>